import Rails from "@rails/ujs";
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) { Rails.start(); }

import 'swiper/swiper.scss';
import 'nouislider/dist/nouislider.css';
import '../css/application.sass';
import '../javascripts/helpers/ResizeSensor'
import 'sticky-sidebar/dist/sticky-sidebar.min'

require.context('../fonts', true)
require.context('../images', true)

import "../javascripts/globalEventListener"
import "../javascripts/overlay"
import "../javascripts/category-menu"
import "../javascripts/sticky-header"
import "../javascripts/slider-init"
import "../javascripts/product-variant-switcher"
import "../javascripts/product-quantity-change"
import "../javascripts/categories-filter"
import "../javascripts/swiper-init"
import "../javascripts/company-map"
import "../javascripts/cart"
import "../javascripts/search"
import "../javascripts/yandex-mertika"
import '../javascripts/sticky-sidebar'
import '../javascripts/properties-slider-init'
