const mobileOffset = 126
const mobileMaxWidth = 619
const desktopOffset = 68

stickySidebarInit = function (topSpacing = desktopOffset) {
  const sidebarSelector = document.querySelector('.js-sticky-sidebar-content')

  if (sidebarSelector) {
    window.filterSidebar = new StickySidebar('.js-sticky-sidebar-content', {
      topSpacing: topSpacing,
      bottomSpacing: 60,
      containerSelector: '.js-sticky-sidebar-wrapper',
      innerWrapperSelector: '.sidebar__inner',
      resizeSensor: true
    })
  }
}

debounce = function (func, time = 100){
  let timer;
  return function(event){
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
}

stickySidebarRestart = function () {
  if (window.filterSidebar) {
    window.filterSidebar.destroy();
  }
  if (window.innerWidth > 619) {
    stickySidebarInit(desktopOffset)
  } else {
    stickySidebarInit(mobileOffset)
  }
}

document.addEventListener("DOMContentLoaded", function() {
  const topSpacing = window.innerWidth > mobileMaxWidth ? desktopOffset : mobileOffset
  stickySidebarInit(topSpacing)
});

window.addEventListener('resize', debounce(stickySidebarRestart, 150))
