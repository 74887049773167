window.onscroll = function(){stickyMenu()};

function stickyMenu() {
  var topBars = document.querySelectorAll(".js-sticky-header");
  var topBarOffset = document.querySelector(".js-sticky-header-offset");
  var stickyClass = 'header-sticked';

  if (window.pageYOffset > topBarOffset.clientHeight) {
    for (var a = 0; a < topBars.length; a++) {
      topBars[a].classList.add(stickyClass);
    }
  } else {
    for (var i = 0; i < topBars.length; i++) {
      topBars[i].classList.remove(stickyClass);
    }
  }
}